// AUTO-GENERATED -- DO NOT MODIFY -- source is found in /config/app.config.js
/* tslint:disable */

//noinspection JSUnusedGlobalSymbols

config = {
  common: {
    releaseId: '1669123872136' || null,
    forceDebugging: false, // log debugger messages no matter the env - automatically on for development
    remoteDebugging: false, // use with caution, will send any logger.debug() to Sentry and co
    protocol: 'https:',
    sentry: {
      enabled: true,
      dsn: 'https://fb16085182f24cf2beb3673adb01a700@sentry.io/1530800'
    },
    mixpanel: {
      enabled: true,
      token: '6a7435e77679f40394a470fcb06e8a8f'
    },
    firebase: {
      enabled: false
    },
    brightcove: {
      accountId: '6093793384001',
      playerId: '6eO1k7VXK',
      policyKey: 'BCpkADawqM3VwKujInOHAoc0va-Uc682TA7VITTTsQQ1oaCr9uRV7Nixe0Hw_SNcVTtVN4VlQJlyBnrJbV-B5hEV9Ffuv9oYN8Y0pSETIWAwDuZAKEwI6WuoKwMz6bzRCdOmc46f8SIwYPJT'
    },
    jwplayer: {
      playerId: 'KFMseWJE'
    },
    device: {
      device_available: false,
      device_cordova: 'unknown',
      device_isVirtual: false,
      device_manufacturer: 'unknown',
      device_model: 'unknown',
      device_platform: 'unknown',
      device_serial: 'unknown',
      device_uuid: null,
      device_version: 'unknown'
    },

    // urls
    companyWebsite: 'drjud.com',
    companyWebsiteUrl: 'https://drjud.com',
    privacyTermsUri: '/terms',
    helpCenterUrl: 'https://claritasmindsciences.zendesk.com/hc/',

    // help center articles
    howToResetPasswordArticle: 'en-us/articles/4412704404371-How-can-I-reset-my-password-',

    // subscriptions
    linkToItunesSubscriptions: 'itmss://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/manageSubscriptions',

    // animations
    pushPageTransition: {
      animate: true,
      animation: 'md-transition',
      direction: 'forward'
    },
    popPageTransition: {
      animate: true,
      animation: 'md-transition',
      direction: 'back'
    },
    openPlayerTransition: {
      animate: true,
      animation: 'ios-transition',
      direction: 'forward',
      duration: 200
    },
    closePlayerTransition: {
      animate: true,
      animation: 'ios-transition',
      direction: 'back',
      duration: 200
    },
    wizardTransition: {
      enterAnimation: 'modal-md-slide-in',
      leaveAnimation: 'modal-md-slide-out'
    },
    onboardingTransition: {
      enterAnimation: 'modal-md-slide-in',
      leaveAnimation: 'modal-md-slide-out'
    },

    // to be generated by program
    programCode: null,
    name: null,
    gaTrackingId: null,
    totalModules: null,
    trialModules: null,
    appUrl: null,
    webUrl: null,
    communityAuthEndpoint: null,

    // app hosts and params used by the config service to generate all hosts
    appDomain: null, // will be set for each program and overriden by envinronment if available
    apiHostname: null, // will be generate for each environment
    webappHostname: null, // will be set for each environment
    sharecareOauthUrl: null, // wil be set for each environment

    // Sharecare SSO
    sharecareClientId: 'ms-cravingtoquit',
    sharecareInitPath: '/init_sharecare', // will be set for each environment
    authorizePath: '/authorize',
    preauthPath: '/preauth',
    ssoErrorPath: '/sso_error',

    // Device Integrations
    fitbitCallbackDevicesPath: '/fitbit_callback_devices',
    fitbitCallbackWeightPath: '/fitbit_callback_weight',

    // to be generated by env
    apiEndpointPrefix: '/api/v5',
    webSocketEndpoint: '/cable',
    environment: null,
    apiHost: null,
    apiUseSsl: null,
    useIridium: null,
    iridiumApiHost: null,
    iridiumApiEndpointPrefix: null,
    iridiumApiUseSsl: null,

    // community related
    communityApiHost: null,
    communityApiEndpointPrefix: null,
    communityCableEndpoint: null,

    // deprecated
    apiUrl: null,

    // Don't change, use config.xml instead
    app: {
      app_version: '6.4.1',
      app_build: 141195,
      package_name: undefined
    }
  },

  // Namespaces
  events: {
    connection: 'clarity.connection',
    logout: 'clarity.logout',
    unauthorized: 'clarity.unauthorized'
  },

  program: {
    programCode: 'ern',
    dppwlProgramCode: 'dppwl',
    name: 'Eat Right Now',
    shortName: 'Eat Right Now',
    languages: ['en', 'es'],
    totalModules: 28,
    trialModules: 4,
    internalServerHost: 'localhost',
    internalServerPort: 8181,
    appUrl: 'https://app.goeatrightnow.com',
    dppwlUrl: 'https://app.dweatrightnow.com',
    webUrl: 'https://goeatrightnow.com',
    appStoreId: '1052785076',
    googlePlayId: 'com.claritasmindsciences.EatRightNowCommercial',
    appStoreUrl: 'https://itunes.apple.com/us/app/eat-right-now/id1052785076?ls=1&mt=8',
    googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.claritasmindsciences.EatRightNowCommercial',
    communityAuthEndpoint: '/programs/ERN/auth/token',
    communityViaLaptopTopicUri: '/t/how-do-i-access-my-account-on-my-computer-vs-app/7584/3',
    supportEmail: 'support@goeatrightnow.com',
    avatar: 'user-checkmark-nobg-jud',
    appDomain: 'goeatrightnow.com',
    dppwlDomain: 'dweatrightnow.com',
    mobileUrlScheme: 'eatrightnow',
    weeklyCallsEndpoint: '/ern_weekly_calls',
    dppwlWeeklyCallsEndpoint: '/dppwl_weekly_calls',
    stripe: {
      enabled: true,
      key: 'pk_test_iymFFot7Sd5xOsHukb2pDMP4',
    },
    jwplayer: true,
    intercom: {
      enabled: false
    },
    iridium: {
      enabled: true
    },
    gtm: {
      containerId: 'GTM-592SMH2'
    },
    kochava: {
      androidAppGuid: 'koern-stg-android-f0aox0fn',
      iosAppGuid: 'koern-stg-ios-42dgzt48l',
      webAppGuid: 'koern-stg-web-ezyli'
    },


    blank: null
  },

  environment: {

    environment: 'dev',
    communityApiHost: 'community.dev.mindsciences.net',
    communityApiEndpointPrefix: '/api/v2',
    communityCableEndpoint: 'community-cable.dev.mindsciences.net',
    apiUseSsl: true,
    sharecareOauthUrl: 'https://auth.stage.sharecare.com/oauth2/authorize',
    urlVideoSalt: '667mBNigLxXynDsVeUay',
    iridiumApiHost: 'iridium.dev.mindsciences.net',
    useIridium: true,
    iridiumApiEndpointPrefix: '/api/v3',
    iridiumApiUseSsl: true,
    recaptchaSiteKey: '6LcG3dgbAAAAALRulYzFBkRZ87wGzUnm3BlEH-0W',
    // deprecated
    apiUrl: 'https://app.dev.mindsciences.net/api/v4',
    apiHostname: 'app.dev',
    webappHostname: 'webapp.dev',




    blank: null
  },

  buildPlatform: 'web',
  end: true
};
